<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col cols="12" md="3" class="d-flex align-center justify-center">
        <v-img :src="end.logoCinza" style="height: 5rem !important;" contain></v-img>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-center justify-center textHeader fontMontserrat">
        <p>Pró-Clínica, sua Clínica de Dermatologia em <span class="textBlue">{{ end.cidade }}</span></p>
      </v-col>
      <v-col cols="12" md="3" class="d-flex align-center justify-center">
        <button class="bgBlue btnHeader white--text" @click="abrirDialogWhats">Agende sua Consulta</button>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'pa-16'">
      <v-col cols="12" md="4" offset-md="2" class="d-flex align-center justify-center">
        <div class="d-flex flex-column align-start justify-center" style="gap: 1.5rem">
          <h1 class="textBlue">Sua pele é o reflexo da sua saúde.</h1>
          <p class="textGray" style="font-weight: 600;">
            Confie nos dermatologistas da <span class="textBlue">Pró-Clínica</span> para cuidar bem dela.
          </p>
          <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
          <div :class="intBreakPoint >= 2 ? 'd-flex align-center pa-0 ma-0' : 'd-flex flex-column align-center pa-0 ma-0'"
            style="width: 100%">
            <v-col cols="12" md="6" :class="intBreakPoint >= 2 ? 'd-flex align-center pl-0' : 'd-flex align-center'">
              <div class="d-flex align-center justify-center details" style="gap: 1rem">
                <img src="@/assets/lpsEspecialidade/dermatologia/logoConsultas.png" style="width: 2rem;">
                <span class="textBlue" style="font-weight: bold;">CONSULTAS</span>
              </div>
            </v-col>
            <v-col cols="12" md="6" :class="intBreakPoint >= 2 ? 'd-flex align-center pr-0' : 'd-flex align-center'">
              <div class="d-flex align-center justify-center details" style="gap: 1rem">
                <img src="@/assets/lpsEspecialidade/dermatologia/logoExames.png" style="width: 2rem;">
                <span class="textBlue" style="font-weight: bold;">EXAMES</span>
              </div>
            </v-col>
          </div>
          <p class="textBlue mt-4" style="font-weight: 700;">Nossos dermatologistas estão prontos para te atender com
            excelência, comprometimento e foco
            total na sua qualidade de vida.</p>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="d-flex align-center justify-center">
        <v-img :src="end.dermatoHeroImg" style="height: 40rem !important;" contain></v-img>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 ? 'bgBlue pa-16' : 'bgBlue'">
      <v-col cols="12" md="4" offset-md="2" class="d-flex flex-column align-center justify-center">
        <p class="textBlue"
          style="background-color: #fff; border-radius: 1rem; padding: 0.5rem 1rem; font-weight: 800; font-size: 1.5rem; line-height: 1;">
          Confie nos
          dermatologistas da Pró-Clínica para cuidar bem da sua pele
        </p>
        <p class="white--text" style="font-weight: 400;">
          Na Pró-Clínica você pode agendar a sua consulta com Dermatologista em {{ end.cidade }} em minutos através do
          Whatsapp com preço justo e datas próximas.
        </p>
        <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
      </v-col>
      <v-col cols="12" md="4" class="d-flex align-center justify-center">
        <v-img :src="end.consultas" style="height: 20rem !important; cursor: pointer;" contain
          @click="abrirDialogWhats"></v-img>
      </v-col>

      <v-col cols="12" md="12" class="d-flex align-center justify-center">
        <p class="white--text mt-4" style="font-size: 1.5rem; font-weight: 800;">Tudo isso sem filas, sem carência e sem
          necessidade de convênio. <br />
          A melhor maneira de cuidar da sua saúde de maneira preventiva.</p>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'pt-16'">
      <v-col cols="12" md="8" offset-md="2" class="d-flex flex-column align-start justify-center">
        <h2 class="bgBlue white--text" style="padding: 0.5rem 1rem; border-radius: 1rem; font-size: 2rem">
          Em dúvida se você deve marcar uma consulta com um dermatologista?
        </h2>
        <div class="mt-8">
          <p class="textBlue" style="font-weight: 800; font-size: 1.5rem;">Existem várias situações ou sintomas em que é
            aconselhável agendar uma consulta com um dermatologista.</p>
          <p class="textBlue" style="font-weight: 800;">Confira as principais:</p>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" offset-md="2" class="d-flex flex-column align-center justify-center">
        <v-img :src="end.dermatoSituacoes" style="height: 20rem !important;" contain></v-img>
      </v-col>
      <v-col cols="12" md="4" class="d-flex flex-column align-center justify-center">
        <v-list dense>
          <v-list-item v-for="item, index in items" :key="index"
            :class="intBreakPoint >= 2 ? 'textBlue mt-16' : 'textBlue mt-4'">
            <div class="d-flex align-center justify-start">
              <v-img src="../../assets/lpsEspecialidade/dermatologia/ellipse.png"
                style="width: 1rem !important; height: 1rem !important;" contain></v-img>
              <span style="font-weight: 700;" class="pl-4">
                {{ item }}
              </span>
            </div>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row :class="intBreakPoint >= 2 && 'pa-16'">
      <v-col cols="12" md="4" offset-md="2" class="d-flex flex-column align-center justify-center">
        <p class="textBlue" style="font-weight: 800;">
          Um dermatologista é especializado em investigar, diagnosticar e tratar distúrbios de pele, e pode identificar
          possíveis doenças dermatológicas em estágios iniciais se consultado periodicamente para um check-up anual, por
          exemplo.
        </p>
        <p class="textGray" style="font-weight: 800;">*É fundamental lembrar que essas são apenas algumas situações ou
          sintomas que podem indicar a
          necessidade de
          consultar um dermatologista. Sempre consulte um profissional de saúde qualificado para avaliar e diagnosticar
          corretamente sua condição e fornecer o tratamento adequado.</p>
        <p class="textBlue" style="font-weight: 800;">
          Se identificou com um ou mais sintomas? É hora de agendar sua consulta com um dermatologista em {{ end.cidade }}
        </p>
        <button class="btnCTA" @click="abrirDialogWhats">Agendar minha consulta</button>
      </v-col>
      <v-col cols="12" md="4" class="d-flex flex-column align-center justify-center">
        <v-img :src="end.dermatoTratamento" style="height: 20rem !important;" contain></v-img>
      </v-col>
    </v-row>

    <footer>
      <v-row style="background-color: #f5f5f5">
        <v-col cols="12" md="12" class="d-flex align-center justify-center">
          <a href="https://abaremarketing.com/" target="_blank">
            <v-img style="max-width: 3rem" src="@/assets/abareLogoPreto.webp" contain></v-img>
          </a>
          <router-link to="/">
            <v-img style="max-width: 8rem" :src="end.logoPreto" contain></v-img>
          </router-link>
        </v-col>
      </v-row>
    </footer>
  </v-container>
</template>

<script>
export default {
  name: 'Dermatologia',

  data() {
    return {
      items: [
        "Problemas persistentes de acne, eczema, psoríase ou outras condições de pele.", "Queda excessiva de cabelo ou problemas no couro cabeludo.", "Alterações suspeitas nos sinais ou pintas.", "Lesões ou manchas na pele que mudam de cor, tamanho ou formato.", "Coceira intensa, irritação ou inflamação na pele."
      ]
    };
  },
  methods: {
    abrirDialogWhats() {
      this.$root.$emit('show-whats');
    },
  },
  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 0;
        case 'sm':
          return 1;
        case 'md':
          return 2;
        case 'lg':
          return 3;
        case 'xl':
          return 4;
        default:
          return 0;
      }
    },

    end() {
      return this.$store.getters.unidade;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');


* {
  font-family: Inter, Montserrat, Roboto, sans-serif !important;
}

@media (max-width: 600px) {
  .mediumFont {
    font-size: 1.2rem !important
  }

  h1 {
    line-height: 2rem !important;
    font-size: 1.5rem !important;
  }
}

@media (min-width: 1920px) {
  h1 {
    font-size: 2rem !important;
  }
}


h1 {
  font-size: 1.4rem;
}


.textHeader {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin: 0;
  padding: 0;
}

.textBlue {
  color: #085A9E !important;
}

.textGray {
  color: #817F7E
}

.bgBlue {
  background-color: #085A9E;
}

.btnHeader {
  padding: 0.5rem 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.btnCTA {
  background-color: #25D366;
  color: #fff;
  width: 100%;
  padding: 1rem 0;
  font-weight: 700;
  text-transform: uppercase;
}

.details {
  /* border: 1px solid #ccc; */
  margin: 0;
  width: 100% !important;
  height: 4rem;
  /* box-shadow: rgb(0, 0, 0, 0.3) 0px 3px 0; */
}
</style>